import React from 'react';
import {
  string,
  oneOfType,
  bool,
  shape,
} from 'prop-types';
import LazyLoad from 'react-lazyload';

import {
  item,
} from './style.module.css';

const ImagePost = ({ title, link, image }) => {
  let titleNode = null;
  if (title !== 'image-post') {
    titleNode = link
      ? <a href={link} target="_blank" rel="noopener noreferrer">{title}</a>
      : <h1>{title}</h1>;
  }

  return (
    <div className={item}>
      <LazyLoad offset={1000} height={200} once>
        <img src={`${image.url}`} alt="" />
      </LazyLoad>
      { titleNode }
    </div>
  );
};

ImagePost.propTypes = {
  title: string,
  link: oneOfType([bool, string]),
  image: shape({}),
};

ImagePost.defaultProps = {
  title: '',
  link: false,
  image: {},
};

export default ImagePost;
