import React from 'react';

import {
  oneOfType,
  bool,
  string,
  shape,
} from 'prop-types';

import {
  container,
} from './style.module.css';

const ImageItem = ({ image, className }) => {
  if (!image || !image.id) {
    return <span />;
  }

  return (
    <div className={`${className} ${container}`}>
      <img src={`${image.media_endpoint}/w_2400/${image.filename}`} alt="" />
    </div>
  );
};

ImageItem.propTypes = {
  image: oneOfType([bool, shape({})]),
  className: string,
};

ImageItem.defaultProps = {
  image: false,
  className: '',
};

export default ImageItem;
