import React from 'react';
import { string } from 'prop-types';

import {
  item,
} from './style.module.css';

const TextPost = ({ title, content }) => (
  <div className={item}>
    <h1>{title}</h1>
    <div>{content}</div>
  </div>
);

TextPost.propTypes = {
  title: string,
  content: string,
};

TextPost.defaultProps = {
  title: '',
  content: '',
};

export default TextPost;
