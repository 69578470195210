/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import {
  string,
  func,
} from 'prop-types';

import isTouch from '../../utils/isTouch';

import {
  container,
  link,
  canvasClass,
} from './style.module.css';

const dpi = 2;
const padding = 20 * dpi;
const paddingBottom = 23 * dpi;
const margin = padding + 10;
const fontWeight = 600;
const lineHeight = 58 * dpi;
const strokeWeight = 4 * dpi;
const radius = 8 * dpi;
const imgMargin = 30;

class TextWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      over: false,
    };

    const {
      canvas,
      width,
      height,
    } = this.drawText({ text: props.text, inverse: false });
    this.width = width / dpi;
    this.height = height / dpi;
    this.bg = canvas.toDataURL();

    const {
      canvas: overCanvas,
    } = this.drawText({ text: props.text, inverse: true });
    this.overBg = overCanvas.toDataURL();

    this.obs = new IntersectionObserver((e) => {
      const { intersectionRatio } = e[0];
      const { over } = this.state;
      if (intersectionRatio === 1 && !over) {
        this.setState({ over: true });
      } else if (over) {
        this.setState({ over: false });
      }
    }, {
      threshold: [0.9, 1],
      rootMargin: '-70px 0px',
      // rootMargin: `-${window.innerHeight * 0.33}px 0px`,
    });
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.sizeCallback({
      width: this.width + imgMargin,
      height: this.height + imgMargin,
    });

    if (this.ref) {
      if (isTouch()) {
        this.obs.observe(this.ref);
      }
    }
  }

  createCanvas = (width, height, className) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.classList.add(className);
    return canvas;
  };

  setContextFont = (ctx) => {
    const fontSize = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--medium').replace('px', ''), 10);
    ctx.font = `${fontWeight} ${fontSize * dpi}px/${lineHeight}px Mabry`;
    ctx.textBaseline = 'top';
    ctx.textAlign = 'center';
  };

  getIndexesForLine = (i, count) => {
    const numAfter = (count - (i + 1)) * 4;
    const tr = (i * 2) + 0;
    const br = (i * 2) + 1;
    const tl = br + numAfter + 2;
    const bl = br + numAfter + 1;

    return {
      tr,
      br,
      tl,
      bl,
    };
  };

  drawOutline = (ctx, points, rounded = true) => {
    ctx.beginPath();
    ctx.moveTo(points[points.length - 1].x + radius, points[points.length - 1].y);
    for (let i = 0; i < points.length; i += 1) {
      const { x, y } = points[i];
      let nextX = 0;
      let nextY = 0;
      if (i < points.length - 1) {
        nextX = points[i + 1].x;
        nextY = points[i + 1].y;
      } else {
        nextX = points[0].x;
        nextY = points[0].y;
      }
      if (rounded) {
        ctx.arcTo(
          x, y,
          nextX, nextY,
          radius,
        );
      } else {
        ctx.lineTo(x, y);
      }
    }
  };

  drawText = ({ text, inverse }) => {
    const lines = text.toUpperCase().split(' ');

    const canvas = this.createCanvas(1000, 1000, canvasClass);
    const ctx = canvas.getContext('2d');
    this.setContextFont(ctx);

    let width = 0;
    const sizes = lines.map((l) => {
      const mt = ctx.measureText(l);
      if (mt.width > width) {
        width = mt.width;
      }
      return mt;
    });

    width += margin * 2;
    const height = lines.length * lineHeight + (margin * dpi);
    const center = width / 2;
    canvas.width = width;
    canvas.height = height;

    this.setContextFont(ctx);

    ctx.lineWidth = strokeWeight;
    const points = [];

    const count = lines.length;
    lines.forEach((t, i) => {
      const {
        width: lineWidth,
        actualBoundingBoxDescent: descent,
      } = sizes[i];
      const y = (lineHeight * i) + margin;
      const halfWidth = lineWidth / 2;

      const {
        tr,
        br,
        tl,
        bl,
      } = this.getIndexesForLine(i, count);

      points[tr] = {
        x: (center + halfWidth) + padding,
        y: y - padding,
      };

      points[br] = {
        x: (center + halfWidth) + padding,
        y: (descent + y) + paddingBottom,
      };

      points[bl] = {
        x: (center - halfWidth) - padding,
        y: (descent + y) + paddingBottom,
      };

      points[tl] = {
        x: (center - halfWidth) - padding,
        y: y - padding,
      };

      if (i > 0) {
        const {
          br: brLast,
          bl: blLast,
          tl: tlLast,
          tr: trLast,
        } = this.getIndexesForLine((i - 1), count);

        // adjust height of shared lines
        // always use the y of the wider line
        if (sizes[i - 1].width > lineWidth) {
          points[tr].y = points[brLast].y;
          points[tl].y = points[brLast].y;
        } else {
          points[brLast].y = points[tr].y;
          points[blLast].y = points[tr].y;
        }

        // if the x's are too close together, make them equal
        if (Math.abs(points[tr].x - points[trLast].x) < radius + 3) {
          const newXR = Math.min(points[tr].x, points[trLast].x);
          const newXL = Math.max(points[tl].x, points[tlLast].x);
          points[tr].x = newXR;
          points[br].x = newXR;
          points[tl].x = newXL;
          points[bl].x = newXL;

          points[trLast].x = newXR;
          points[brLast].x = newXR;
          points[tlLast].x = newXL;
          points[blLast].x = newXL;
        }
      }
    });

    //*
    this.drawOutline(ctx, points);
    if (inverse) {
      ctx.fillStyle = '#000';
      ctx.fill();
    }
    ctx.stroke();
    // */

    ctx.fillStyle = inverse ? '#fff' : '#000';
    lines.forEach((t, i) => {
      const y = (lineHeight * i) + margin;
      ctx.fillText(t, center, y);
    });

    return {
      canvas,
      width,
      height,
    };
  };

  render() {
    const {
      text,
      href,
      className,
    } = this.props;

    const { over } = this.state;

    const wrapperStyle = {
      width: this.width + imgMargin,
      height: this.height + imgMargin,
    };

    const linkStyle = {
      width: this.width,
      height: this.height,
      backgroundImage: `url(${over ? this.overBg : this.bg})`,
    };

    return (
      <div
        className={`${container} ${className}`}
        style={wrapperStyle}
        ref={(r) => { this.ref = r; }}
      >
        <a
          style={linkStyle}
          href={href}
          className={link}
          target="_blank"
          rel="noopener noreferrer"
          onMouseOver={() => this.setState({ over: true })}
          onMouseOut={() => this.setState({ over: false })}
        >
          <span className="sr-only">{text}</span>
        </a>
      </div>
    );
  }
}

TextWrapper.propTypes = {
  text: string.isRequired,
  href: string.isRequired,
  className: string,
  sizeCallback: func,
};

TextWrapper.defaultProps = {
  className: '',
  sizeCallback: () => {},
};

export default TextWrapper;
