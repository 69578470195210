/* eslint-disable react/no-danger */
import React, { useEffect, useRef } from 'react';
import { func } from 'prop-types';
import useSWR from 'swr';
import { useWindowResize } from 'beautiful-react-hooks';

import SEO from '../../components/SEO';

import calculateColumnSize from '../../utils/calculateColumnSize';

import {
  container,
  contentContainer,
  image,
  imageFull,
  imageLeft,
  imageRight,
  textBlock,
  imageCaption,
  listMarker,
  endnoteFooter,
  endnoteItem,
  noIndent,
} from './style.module.css';

const alignments = {
  center: imageFull,
  left: imageLeft,
  right: imageRight,
};

const LandAndLabor = ({ setTitle }) => {
  const ref = useRef();
  useEffect(() => {
    setTitle('LAND AND LABOR');
    const { backgroundColor: bodyBg } = getComputedStyle(document.body);
    if (ref && ref.current) {
      const { backgroundColor } = getComputedStyle(ref.current);
      document.body.style.backgroundColor = backgroundColor;
    }
    return () => {
      document.body.style.backgroundColor = bodyBg;
    };
  });

  const { data, error } = useSWR('/land_and_labor');
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  const calcColumnSize = () => {
    if (ref && ref.current) {
      const grid = calculateColumnSize(ref.current);
      if (grid) {
        ref.current.style.setProperty('--colWidth', `${grid.columnWidth}px`);
      }
    }
  };
  const onWindowResize = useWindowResize();
  onWindowResize(calcColumnSize);
  useEffect(calcColumnSize, [data]);

  if (!data) {
    return <div />;
  }

  const { content } = data;
  const endnotes = [];
  const contentNodes = content.map((item) => {
    switch (item.type) {
      case 'Image':
        if (item.content.alignment !== 'center') {
          endnotes.push(item.content.caption);
        }
        return (
          <div
            key={item.uuid}
            className={`${image} ${alignments[item.content.alignment]}`}
          >
            <img
              src={`${item.content.image.media_endpoint}/w_2400/${item.content.image.filename}`}
              alt=""
            />
            {
              (item.content.alignment === 'center') && (
                <div
                  className={imageCaption}
                  dangerouslySetInnerHTML={{ __html: item.content.caption }}
                />
              )
            }
          </div>
        );

      case 'Text':
        return (
          <TextBlock key={item.uuid} content={item.content} />
        );

      default:
        return null;
    }
  });

  return (
    <main className={container} ref={ref}>
      <SEO title="LAND AND LABOR" />
      <div className={contentContainer}>
        {contentNodes}
        <ol className={endnoteFooter}>
          {
            endnotes.map((v) => (
              <li
                key={v}
                className={endnoteItem}
                dangerouslySetInnerHTML={{ __html: v }}
              />
            ))
          }
        </ol>
      </div>
    </main>
  );
};

LandAndLabor.propTypes = {
  setTitle: func.isRequired,
};

const TextBlock = (content) => {
  let { content: html } = content;
  const classes = [textBlock];
  if (html.indexOf('<ul>')) {
    html = html.replace(/<li>([A-Za-z0-9]*)\. (.*)<\/li>/g, (all, marker, text) => (
      `<li><div class="${listMarker}">${marker}</div>${text}</li>`
    ));
    if (html !== content.content) {
      classes.push(noIndent);
    }
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      className={classes.join(' ')}
    />
  );
};

export default LandAndLabor;
