/* eslint-disable react/no-danger */
import React from 'react';
import { shape } from 'prop-types';

import SEO from '../../components/SEO';
import { withAPI } from '../../utils/API';

import {
  container,
  sidebar,
  main,
  content,
  social,
  addresses,
  addressItem,
  people,
  socialItem,
} from './style.module.css';

const Office = ({ data }) => (
  <main className={container}>
    <SEO title="OFFICE" />
    <div className={content}>
      <div className={main} dangerouslySetInnerHTML={{ __html: data.main }} />
      <div className={sidebar}>
        <div className={addresses}>
          {
            data.addresses.map((address) => (
              <div
                key={address.uuid}
                className={addressItem}
                dangerouslySetInnerHTML={{ __html: address.content.body }}
              />
            ))
          }
        </div>
        <div className={people} dangerouslySetInnerHTML={{ __html: data.people }} />
        <div className={social}>
          {
            data.social.map(({ uuid, content: { label, text, url } }) => (
              <div
                key={uuid}
                className={socialItem}
              >
                <h3>{label}</h3>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {text}
                </a>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  </main>
);

Office.propTypes = {
  data: shape({}).isRequired,
};

export default withAPI(Office, '/info');
