import React from 'react';

import {
  container,
} from './style.module.css';

const NotFound = () => (
  <main className={container}>
    <h2>NOT FOUND</h2>
    Sorry, nothing like that here.
  </main>
);

export default NotFound;
