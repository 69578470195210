import React from 'react';
import { string } from 'prop-types';

const LinkItem = ({ title, source, className }) => (
  <div className={`${className} link-type`}>
    <a className="item-inner" href={source.href} target="_blank" rel="noopener noreferrer"><div>{title}</div></a>
  </div>
);

LinkItem.propTypes = {
  title: string,
  source: string,
  className: string,
};

LinkItem.defaultProps = {
  title: '',
  source: '',
  className: 'project-item',
};

export default LinkItem;
