export default (node) => {
  if (node) {
    try {
      const style = window.getComputedStyle(node);
      const gutter = parseFloat(style.getPropertyValue('grid-column-gap').replace('px', ''), 10);

      const cols = style.getPropertyValue('grid-template-columns').split(' ');
      const columnCount = cols.length;
      const columnWidth = parseFloat(cols[0].replace('px', ''), 10);

      return {
        gutter,
        columnWidth,
        columnCount,
      };
    } catch (e) {
      // trap error
      return false;
    }
  }
  return false;
};
