/* eslint-disable react/no-danger */
import React from 'react';
import { string } from 'prop-types';

const TextItem = ({ content, className }) => (
  <div className={`${className} text-type`}>
    <div className="item-inner" dangerouslySetInnerHTML={{ __html: content }} />
  </div>
);

TextItem.propTypes = {
  content: string,
  className: string,
};

TextItem.defaultProps = {
  content: '',
  className: 'project-item',
};

export default TextItem;
