/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line
/* eslint-disable react-hooks/rules-of-hooks */

import React, { useRef, useEffect } from 'react';
import useSWR, { useSWRPages } from 'swr';
import { useViewportSpy } from 'beautiful-react-hooks';

import SEO from '../../components/SEO';

import ImagePost from './ImagePost';
import TextPost from './TextPost';

import TextWrapper from '../../components/TextWrapper';

import {
  linkItem,
  items,
  social,
  scrollSpy,
} from './style.module.css';

const Home = () => {
  const scrollEndRef = useRef();
  const hitBottom = useViewportSpy(scrollEndRef);

  const { pages: postItems, loadMore } = useSWRPages(
    'home-posts',
    ({ offset, withSWR }) => {
      const url = `/post/infinite?page=${offset || 0}`;
      const { data } = withSWR(useSWR(url));

      if (!data) return null;

      return (
        data && data.items && data.items.map((item, i) => {
          switch (item.type) {
            case 'IMAGE':
              return (
                <ImagePost
                  key={`post-${i}}`}
                  image={item.images[0]}
                  title={item.title}
                  link={item.link ? item.link : false}
                />
              );

            case 'LINK':
              return (
                <div className={linkItem} key={`${item.title}-${item.url}`}>
                  <TextWrapper
                    text={item.title}
                    href={item.url}
                  />
                </div>
              );

            case 'TEXT':
              return (
                <TextPost
                  key={`post-${i}}`}
                  content={item.content}
                  title={item.title}
                />
              );

            default:
              return null;
          }
        })
      );
    },
    (SWR) => (SWR.data.next === -1 ? null : SWR.data.next),
    [],
  );

  useEffect(() => {
    if (hitBottom) {
      loadMore();
    }
  }, [hitBottom]);

  return (
    <main>
      <SEO title="HOME" />
      <div className={items}>
        {postItems}
        <div
          ref={scrollEndRef}
          className={scrollSpy}
          style={{ marginTop: hitBottom ? 'auto' : '110vh' }}
        />
      </div>
      <div className={social}>
        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/terremoto_landscape/">
          IG
        </a>
      </div>
    </main>
  );
};

export default Home;
