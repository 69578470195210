/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import {
  string,
} from 'prop-types';

import {
  container,
  playButton,
  controlIcon,
  isPaused,
} from './style.module.css';

import playIcon from './playIcon.svg';
import pauseIcon from './pauseIcon.svg';

const VimeoItem = ({ url, className }) => {
  const [playing, setPlaying] = useState(false);

  if (!url) {
    return <span />;
  }

  return (
    <div className={`${className} ${container}`}>
      <ReactPlayer
        url={url}
        height="100%"
        width="100%"
        playing={playing}
      />
      <button className={`${playButton} ${playing ? 'is-playing' : isPaused}`} type="button" onClick={() => setPlaying(!playing)}>
        <img alt="" src={playing ? pauseIcon : playIcon} className={controlIcon} />
      </button>
    </div>
  );
};

VimeoItem.propTypes = {
  url: string,
  className: string,
};

VimeoItem.defaultProps = {
  url: '',
  className: '',
};

export default VimeoItem;
