import React from 'react';
import fetch from 'unfetch';
import { element, string } from 'prop-types';
import useSWR, { SWRConfig } from 'swr';

const APIContext = ({ children, base }) => {
  const fetcher = (url) => fetch(`${base}${url}`).then((r) => r.json());

  return (
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      {children}
    </SWRConfig>
  );
};

APIContext.propTypes = {
  children: element.isRequired,
  base: string.isRequired,
};

const withAPI = (Component, url) => () => {
  const { data, error } = useSWR(url);
  if (error) {
    return (
      <div>
        { process.env.NODE_ENV === 'development' && <b>Error loading</b>}
      </div>
    );
  }
  if (!data) return <div />;
  return <Component data={data} url={url} />;
};

export {
  APIContext,
  withAPI,
};
