/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  bool,
  string,
  oneOfType,
  func,
} from 'prop-types';
import { NavLink } from 'react-router-dom';

import {
  container,
  lines,
  line,
  small,
} from './style.module.css';

const ConditionalLink = ({ to, onClick, children }) => (
  to === false ? children : <NavLink to={to} onClick={onClick}>{children}</NavLink>
);

ConditionalLink.propTypes = {
  children: string.isRequired,
  to: oneOfType([bool, string]),
  onClick: func,
};

ConditionalLink.defaultProps = {
  to: false,
  onClick: () => {},
};

const SplitHeader = ({
  text,
  smallSize,
  className,
  linkTo,
  onClick,
}) => {
  const textLines = [];
  text.split(' ').forEach((l, i) => {
    if (l.match(/[^a-zA-Z0-9]/)) {
      if (i > 0) {
        textLines[i - 1] += ` ${l}`;
      }
      return;
    }
    textLines.push(l);
  });

  return (
    <h1 className={`${container} ${smallSize ? small : ''} ${className}`}>
      <div className="sr-only">{text}</div>
      <div className={lines} aria-hidden>
        {
          textLines.map((l, i) => (
            <div
              key={`${l}-${i}`}
              className={line}
              style={{
                paddingTop: `${i}em`,
                paddingLeft: 0, // smallSize ? `${i / 2}em` : 0,
              }}
            >
              <ConditionalLink to={linkTo} onClick={onClick}>{l}</ConditionalLink>
            </div>
          ))
        }
      </div>
    </h1>
  );
};

SplitHeader.propTypes = {
  text: string,
  smallSize: bool,
  className: string,
  linkTo: oneOfType([bool, string]),
  onClick: func,
};

SplitHeader.defaultProps = {
  text: '',
  smallSize: false,
  className: '',
  linkTo: false,
  onClick: () => {},
};

export default SplitHeader;
