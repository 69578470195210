import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { APIContext } from '../../utils/API';

import Home from '../Home';
import NotFound from '../NotFound';
import Office from '../Office';
import Project from '../Project';
import {
  ProjectList,
  SmallProjectList,
} from '../ProjectList';
import Writing from '../Writing';
import LandAndLabor from '../LandAndLabor';
import Header from '../../components/Header';

import {
  DEV_API_ROOT,
  PROD_API_ROOT,
} from '../../config';

import './style.css';

const App = () => {
  const [title, setTitle] = useState(false);

  const Restore = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
      setTitle(false);
    }, [pathname]);

    return null;
  };

  return (
    <Router>
      <div className="app">
        <Header title={title} />
        <Restore />
        <APIContext base={process.env.NODE_ENV === 'development' ? DEV_API_ROOT : PROD_API_ROOT}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/office">
              <Office />
            </Route>
            <Route path="/land-and-labor">
              <LandAndLabor setTitle={setTitle} />
            </Route>
            <Route path="/writings">
              <Writing />
            </Route>
            <Route path="/small-works">
              <SmallProjectList />
            </Route>
            <Route path="/projects">
              <ProjectList />
            </Route>
            <Route path="/project/:id">
              <Project setTitle={setTitle} />
            </Route>
            <Redirect from="/project" to="/projects" />
            <Redirect from="/index" to="/projects" />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </APIContext>
      </div>
    </Router>
  );
};

export default App;
