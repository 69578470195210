import React, { useState } from 'react';
import { string, oneOfType, bool } from 'prop-types';
import { NavLink } from 'react-router-dom';

import SplitHeader from '../SplitHeader';

import {
  container,
  titleWrapper,
  navWrapper,
  menu,
  activeLink,
  menuLine,
  mobileMenuControlWrapper,
  mobileMenuControl,
  mobileMenuControlMobileActive,
  mobileMenuOpen,
} from './style.module.css';

const defaultTitle = 'TERRE MOTO';

const Header = ({ title }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const titleNode = (title !== false && title !== defaultTitle)
    ? <SplitHeader className={titleWrapper} text={title} smallSize />
    : (
      <SplitHeader
        className={titleWrapper}
        text={defaultTitle}
        onClick={() => setMenuOpen(false)}
      />
    );

  return (
    <header className={container}>
      {titleNode}
      <div className={`${navWrapper} ${menuOpen ? mobileMenuOpen : ''}`}>
        <nav className={menu} id="menu">
          <NavLink to="/" activeClassName={activeLink} exact onClick={() => setMenuOpen(false)}>Terremoto</NavLink>
          <NavLink
            to="/projects"
            activeClassName={activeLink}
            onClick={() => setMenuOpen(false)}
            isActive={(match, location) => {
              const { pathname = false } = location;
              return pathname && (pathname.indexOf('/project') >= 0);
            }}
          >
            Projects
          </NavLink>
          <NavLink to="/small-works" activeClassName={activeLink} onClick={() => setMenuOpen(false)}>Small Works</NavLink>
          <NavLink to="/writings" activeClassName={activeLink} onClick={() => setMenuOpen(false)}>Writings & Rambles</NavLink>
          <NavLink to="/land-and-labor" activeClassName={activeLink} onClick={() => setMenuOpen(false)}>Land and Labor</NavLink>
          <NavLink to="/office" activeClassName={activeLink} onClick={() => setMenuOpen(false)}>Office</NavLink>
        </nav>
      </div>
      <div className={mobileMenuControlWrapper}>
        <button
          type="button"
          className={mobileMenuControl}
          aria-label="Open Menu"
          aria-controls="menu"
          aria-expanded={menuOpen}
          onClick={() => { setMenuOpen(!menuOpen); }}
        >
          <svg className={menuOpen ? mobileMenuControlMobileActive : ''} version="1.1" x="0px" y="0px" width="38" height="38" viewBox="0 0 38 38" aria-hidden="true">
            {
              !menuOpen && (
                <>
                  <rect className={menuLine} x="0.5" y="6.3" width="37" height="3.4" />
                  <rect className={menuLine} x="0.5" y="17.3" width="37" height="3.4" />
                  <rect className={menuLine} x="0.5" y="28.3" width="37" height="3.4" />
                </>
              )
            }
            {
              menuOpen && (
                <>
                  <rect className={menuLine} x="0.5" y="17.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -7.8403 18.9877)" width="37" height="3.4" />
                  <rect className={menuLine} x="17.3" y="0.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -7.8403 18.9877)" width="3.4" height="37" />
                </>
              )
            }
          </svg>
        </button>
      </div>
    </header>
  );
};

Header.propTypes = {
  title: oneOfType([bool, string]),
};

Header.defaultProps = {
  title: false,
};

export default Header;
