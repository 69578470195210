import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

import SEO from '../../components/SEO';
import { withAPI } from '../../utils/API';

import {
  container,
  item,
  thumb,
  name,
} from './style.module.css';

const Projects = ({ data, url }) => (
  <main className={container}>
    <SEO title={url === '/project/small-works' ? 'SMALL WORKS' : 'PROJECTS'} />
    {
      data.map(({ cover, slug, title }) => {
        const style = {
          backgroundImage: `url(${cover.media_endpoint}/w_700/${cover.filename})`,
        };
        return (
          <div className={item} key={slug}>
            <Link to={`/project/${slug}`}>
              <div className={thumb} style={style} />
              <h2 className={name}>{title}</h2>
            </Link>
          </div>
        );
      })
    }
  </main>
);

Projects.propTypes = {
  data: arrayOf(shape({})).isRequired,
  url: string.isRequired,
};

export const ProjectList = withAPI(Projects, '/project');
export const SmallProjectList = withAPI(Projects, '/project/small-works');
