import React from 'react';
import { string } from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({ title }) => (
  <Helmet titleTemplate="%s | TERREMOTO.LA">
    <title>{title}</title>
    <meta
      name="description"
      content="Visual Log of Projects / Work at Terremoto / A Landscape Office / LA + SF California"
    />
    <meta property="og:title" content="Terremoto Landscape" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="http://terremoto.la" />
    <meta property="og:site_name" content="Terremoto Landscape" />
    <meta property="og:description" content="Visual Log of Projects / Work at Terremoto / A Landscape Office / LA + SF California" />
    <meta property="og:image" content="http://terremoto.la/share-image.jpg" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="776" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content="Terremoto Landscape" />
    <meta name="twitter:url" content="http://terremoto.la" />
    <meta name="twitter:site" content="Terremoto Landscape" />
    <meta name="twitter:title" content="Terremoto Landscape" />
    <meta name="twitter:description" content="Visual Log of Projects / Work at Terremoto / A Landscape Office / LA + SF California" />
    <meta name="twitter:image" content="http://terremoto.la/share-image.jpg" />
  </Helmet>
);

SEO.propTypes = {
  title: string,
};

SEO.defaultProps = {
  title: 'TERREMOTO',
};

export default SEO;
