import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import {
  oneOfType,
  bool,
  string,
  shape,
} from 'prop-types';

import {
  container,
} from './style.module.css';

const AudioItem = ({ content, className }) => {
  if (!content || !content.audio || !content.audio.url) {
    return <span />;
  }

  return (
    <div className={`${className} ${container}`}>
      <AudioPlayer
        showJumpControls={false}
        customAdditionalControls={[]}
        autoPlay
        showFilledProgress={false}
        showDownloadProgress={false}
        src={content.audio.url}
        header={content.title}
      />
    </div>
  );
};

AudioItem.propTypes = {
  content: oneOfType([bool, shape({})]),
  className: string,
};

AudioItem.defaultProps = {
  content: false,
  className: '',
};

export default AudioItem;
